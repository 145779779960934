<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="12">
          <ul class="list-unstyled">
            <li class="row border-bottom pt-3 pb-3" v-for="(item, index) in listData" :key="index">
              <b-col md="10" class="d-flex">
                <div class="wishlist-img">
                  <b-img
                    height="100"
                    width="100"
                    class="mr-3 border border-left-0"
                    alt="placeholder"
                    :src="item.images.bigImage"
                  ></b-img>
                </div>
                <div>
                  <h6 class="mt-0 mb-1">{{ item.description_summary }}</h6>
                  <p
                    class="mb-1 text-uppercase"
                    v-bind:style="{ color: '#afafaf' }"
                  >{{ item.Brand }}</p>
                  <h4 class="mb-0">&#x20B9; {{ item.Price }}</h4>
                  <p class="mb-0">
                    Provider :
                    <router-link
                      :to="{path: `/shopDetails/${item.provider}/${item.providerName}`}"
                    >{{ item.providerName }}</router-link>
                  </p>
                </div>
              </b-col>
              <b-col md="2">
                <b-button class="mt-3" variant="primary w-100" @click="addtowishlist(item)">Add</b-button>
                <b-button
                  class="mt-3"
                  variant="outline-primary w-100"
                  @click="removeToWishList(item)"
                >Remove</b-button>
              </b-col>
            </li>
          </ul>
          <b-col
            md="12"
            :style="{ display: listData.length == 0 ? '' : 'none' }"
            class="text-center"
          >
            <div class="text-center">
              <b-img inline src="img/empty-wishlist.png" fluid></b-img>
              <h3 class="font-weight-bold mb-4 mt-4">Your Wishlist is Empty</h3>
              <b-navbar-brand>
                <b-button
                  @click="$router.push('/')"
                  variant="primary"
                  class="mb-2"
                >Continue Shopping</b-button>
              </b-navbar-brand>
            </div>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "wishlist",
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState({
      listData: (state) => state.wishlist.listData,
      cartData: (state) => state.checkout.cartData,
    }),
  },
  props: {
    callCheckout: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      fetchWishlist: "wishlist/listWishList",
    }),
    async addtowishlist(item) {
      try {
        if (this.cartData.length > 0) {
          if (this.cartData[0].provider == item.provider) {
            await this.$store.dispatch("checkout/increaseCartQty", {
              cartItem: item,
              callApi: this.callCheckout,
            });
            this.loading = false;
            if (this.callCheckout)
              await this.$store.dispatch("checkout/checkoutFinal");
          } else {
            this.$swal({
              title: `Sure??`,
              text: "You have product from different seller in your cart.Do you want to remove it and add this item?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                await this.$store.dispatch("checkout/clearCart");
                await this.$store.dispatch("checkout/increaseCartQty", {
                  cartItem: item,
                  callApi: this.callCheckout,
                });
                await this.$store.dispatch("wishlist/removeToWishList", item);
                await this.fetchWishlist();
              }
              this.loading = false;
            });
          }
        } else {
          await this.$store.dispatch("checkout/increaseCartQty", {
            cartItem: item,
            callApi: this.callCheckout,
          });
          await this.$store.dispatch("wishlist/removeToWishList", item);
          await this.fetchWishlist();
          this.loading = false;
          if (this.callCheckout)
            await this.$store.dispatch("checkout/checkoutFinal");
        }
      } catch {
        this.loading = false;
      }
    },
    async removeToWishList(productData) {
      this.isLoading = true;
      await this.$store.dispatch("wishlist/removeToWishList", productData);
      this.$swal({
        group: "item",
        title: "Success",
        text: "Item removed Successfully ",
        type: "success",
        timer: 1000,
      });
      await this.fetchWishlist();
      this.isLoading = false;
    },
  },

  async mounted() {
    await this.fetchWishlist();
  },
};
</script>
<style scoped>
.wishlist-img {
  max-width: 100px;
  max-height: 100px;
  margin-right: 15px;
  text-align: center;
}
.wishlist-img img {
  object-fit: contain;
}
</style>
