var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.listData, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "row border-bottom pt-3 pb-3"
    }, [_c('b-col', {
      staticClass: "d-flex",
      attrs: {
        "md": "10"
      }
    }, [_c('div', {
      staticClass: "wishlist-img"
    }, [_c('b-img', {
      staticClass: "mr-3 border border-left-0",
      attrs: {
        "height": "100",
        "width": "100",
        "alt": "placeholder",
        "src": item.images.bigImage
      }
    })], 1), _c('div', [_c('h6', {
      staticClass: "mt-0 mb-1"
    }, [_vm._v(_vm._s(item.description_summary))]), _c('p', {
      staticClass: "mb-1 text-uppercase",
      style: {
        color: '#afafaf'
      }
    }, [_vm._v(_vm._s(item.Brand))]), _c('h4', {
      staticClass: "mb-0"
    }, [_vm._v("₹ " + _vm._s(item.Price))]), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(" Provider : "), _c('router-link', {
      attrs: {
        "to": {
          path: "/shopDetails/".concat(item.provider, "/").concat(item.providerName)
        }
      }
    }, [_vm._v(_vm._s(item.providerName))])], 1)])]), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-button', {
      staticClass: "mt-3",
      attrs: {
        "variant": "primary w-100"
      },
      on: {
        "click": function click($event) {
          return _vm.addtowishlist(item);
        }
      }
    }, [_vm._v("Add")]), _c('b-button', {
      staticClass: "mt-3",
      attrs: {
        "variant": "outline-primary w-100"
      },
      on: {
        "click": function click($event) {
          return _vm.removeToWishList(item);
        }
      }
    }, [_vm._v("Remove")])], 1)], 1);
  }), 0), _c('b-col', {
    staticClass: "text-center",
    style: {
      display: _vm.listData.length == 0 ? '' : 'none'
    },
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-img', {
    attrs: {
      "inline": "",
      "src": "img/empty-wishlist.png",
      "fluid": ""
    }
  }), _c('h3', {
    staticClass: "font-weight-bold mb-4 mt-4"
  }, [_vm._v("Your Wishlist is Empty")]), _c('b-navbar-brand', [_c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v("Continue Shopping")])], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }